import React, { useCallback, useEffect, useState } from 'react'
import { Button, ButtonGroup, Card, Container } from 'react-bootstrap'
import { Appointments } from './Appointments'
import { ApiClient } from '../../services/api/ApiClient'


import { Forms } from '../../forms/Forms'
import moment from 'moment'
import { IAppointment, IAppointmentForm } from '../../types/models/Appointment'
import { SlotInfo } from 'react-big-calendar'
export const Index: React.FC = () => {
  const [events, setEvents] = useState<IAppointment[]>([])
  const [isFormVisible, setIsFormVisible] = useState(false)
  const [isUpdateFormVisible, setIsUpdateFormVisible] = useState(false)
  const [currentSlot, setCurrentSlot] = useState<SlotInfo | null>(null)
  const [currentAppointment, setCurrentAppointment] = useState<IAppointmentForm | null>(null)
  const [active, setActive] = useState(false) // This controls the scope of the appointments to show
  const refresh = useCallback((active = false, start: string | null = null, end: string | null = null) => {
    if (!start) {
      start = moment().startOf('week').format('YYYY-MM-DD')
    }
    if (!end) {
      end = moment().endOf('week').format('YYYY-MM-DD')
    }
    ApiClient.appointments.index({ active, start, end }).then((response) => {
      setEvents(response)
    })
  }, [])

  useEffect(() => {
    refresh(active)
  }, [active])

  const handleSelectSlot = (slotInfo: SlotInfo) => {
    setCurrentSlot(slotInfo)
    setIsFormVisible(true)
  }


  const handleEventClose = () => {

    setIsFormVisible(false)
    setIsUpdateFormVisible(false)
    refresh(active)
  }

  const handleEventSelect = (event: IAppointmentForm) => {

    if (event.status !== 1 && event.status !== 2) {
      setCurrentAppointment(event)
      setIsUpdateFormVisible(true)
    }
  }

  return (
    <Container className='mt-3'>
      {isFormVisible && <Forms.Event event={currentSlot!} onClose={handleEventClose} />}
      {isUpdateFormVisible && <Forms.Appointment appointment={currentAppointment!} onClose={handleEventClose} />}
      <Card>
        <Card.Header className='d-flex align-items-center justify-content-between'>
          <span>Calendario de Citas</span>
          <div>
            <Button size='sm' className='rounded-0 mx-2' onClick={() => setIsFormVisible(true)}>Nueva Cita</Button>
            <ButtonGroup size='sm'>
              <Button variant={!active ? "primary" : "outline-primary"} className='rounded-0' onClick={() => setActive(false)}>Mostrar Todo</Button>
              <Button variant={active ? "primary" : "outline-primary"} className='rounded-0' onClick={() => setActive(true)}>Mostrar Solo Activas</Button>
            </ButtonGroup>
          </div>
        </Card.Header>
        <Card.Body>

          <Appointments.Calendar events={events} onSelectSlot={handleSelectSlot} onSelectEvent={handleEventSelect} onRangeChange={(start: string | null, end: string | null) => refresh(active, start, end)} />
        </Card.Body>
      </Card>


    </Container>
  )
}